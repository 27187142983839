import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './MySurveys.scss';
import FiltersComponentContainer from './../../Filters/Filters';
import QuickAccessComponentContainer from './../../QuickAccess/QuickAccess';
import Services from './../../Services/Services';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { IDictionaryState } from "../../../states/dictionary-state";
import { IPatientSurveyState } from "../../../states/patient-survey-state";
import { getPatientSurveyList, deletePatientSurvey } from "../../../actions/patient-survey-actions";
import Modal from 'react-bootstrap/Modal'
import UserSurveysForm from '../../../forms/user-surveys-form';
import { IPatientSurvey } from "../../../models/user-surveys";
import { _patientSurveyItem, getPatientSurvey, createPatientSurvey, updatePatientSurvey } from "../../../actions/patient-survey-actions";
import { IFile } from "../../../models/file";
import { IUpload } from '../../../models/upload';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { showConfirm } from '../../Dialogs';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UserFilesAddComponent from './../../../forms/FormComponents/UserFilesAdd';
import { Link } from 'react-router-dom';
import { JsxElement } from 'typescript';
import { LoadingCenter } from "../../Loading/Loading";
import { ILoadingState, LoadingSubject } from '../../../states/loading-state';
import SaveButton from './../SaveButton/SaveButton';
import mySurveys from "./../../../images/medical-card/md_items_icons/my_surveys.png"
const backgroundImageStyle = {
    backgroundImage: `url(${mySurveys})`
};

interface MyProps {
    t: TFunction;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    auth: IAuthState;
    dictionary: IDictionaryState;
    patientSurvey: IPatientSurveyState;
    getPatientSurveyList: () => void;
    deletePatientSurvey: (id: number) => void;
    createPatientSurvey: (survey: IPatientSurvey, uploadFiles: IUpload[], deleleFiles: IFile[], host_type: number, success: () => void) => void;
    updatePatientSurvey: (survey: IPatientSurvey, uploadFiles: IUpload[], deleleFiles: IFile[], host_type: number, success: () => void) => void;
    getPatientSurvey: (id: number) => void;
    _patientSurveyItem: (survey: IPatientSurvey | null) => void;
    fromDocItems: any;
    loading: ILoadingState;
    patientInfoBlock: JSX.Element;
    forDocItem: boolean;
    onSubmit?: (data: IPatientSurvey) => void;
}

interface MyState {
    show: boolean;
    updateItem: boolean;
    selectedFiles?: IUpload[];
    deletedFiles?: IFile[];
    removeMode: {} | null;
    fromDocItems: any;
    forDocItem: boolean;
}

class MySurveysComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);

        this.state = {
            show: false,
            updateItem: false,
            removeMode: null,
            fromDocItems: this.props?.fromDocItems,
            forDocItem: this.props?.fromDocItems,
        };
    }

    onSubmit(data: IPatientSurvey) {
        const proceduresMap = this.props.dictionary.procedures;
        //@ts-ignore
        const procedureId = Object.entries(proceduresMap).find(
            ([id, proc]) => proc.name === data?.procedures[0]
        )?.[0];

        const newData = {
            ...data,
            procedures: procedureId ? [procedureId] : []
        };

        if (this.state.updateItem) {
            //@ts-ignore
            this.props.updatePatientSurvey(newData, this.state.selectedFiles || [], this.state.deletedFiles || [], 3, this.handleClose);
        } else {
            //@ts-ignore
            this.props.createPatientSurvey(newData, this.state.selectedFiles || [], this.state.deletedFiles || [], 3, this.handleClose);
        }
    }

    async confirmDelete() {
        const { t } = this.props;

        if (await showConfirm(t("Are you sure you want to delete this item/items?"))) {
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    let item: any;
                    if (this.props.patientSurvey && this.props.patientSurvey.list) {
                        item = this.props.patientSurvey.list[index];
                    }
                    if (item) {
                        await this.props.deletePatientSurvey(item.id);
                    }
                }
            }
            this.setState({ removeMode: null });
        }
    }

    componentDidMount() {
        this.props.getPatientSurveyList();
    }

    handleClose = () => {
        this.setState({ show: false })
        this.props._patientSurveyItem(null);
        this.setState({ selectedFiles: [], deletedFiles: [] });
    };

    handleShow = () => { this.setState({ show: true }) };

    render() {
        const { fromDocItems, forDocItem } = this.state;
        const { t } = this.props;

        let itemsList = this.props.patientSurvey.list;
        if (fromDocItems) {
            itemsList = fromDocItems;
        }

        const patientSurveyLoading = this.props.loading.subjects.has(LoadingSubject.PatientSurvey);

        return (
            <div className='surveys_wrapper'>
                <Modal centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title>{this.state.updateItem ? t('Change survey') : t('New survey')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='step1'>
                        {
                            fromDocItems && this.state.forDocItem ?
                                Object.entries(this.state.forDocItem).map((itm: any) => {
                                    if (itm[0] !== 'Files') {
                                        return (
                                            <div style={itm[1] ? {} : { display: 'none' }}>
                                                <p
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '0.9rem',
                                                        marginBottom: '5px',
                                                        color: '#121C1E',
                                                    }}
                                                >{itm[0]}</p>
                                                <p
                                                    style={{
                                                        borderBottom: '1px solid #ccc',
                                                        fontSize: '0.8rem',
                                                        paddingBottom: '2px'
                                                    }}
                                                >{itm[1]}</p>
                                            </div>
                                        )
                                    }

                                })
                                :
                                <React.Fragment>
                                    <UserSurveysForm onSubmit={(data: any) => this.onSubmit(data)} onFilesChange={(selectedFiles, deletedFiles) => this.setState({ selectedFiles, deletedFiles })} />

                                    <SaveButton text={t('Save')}
                                        loading={patientSurveyLoading}
                                        onClickFunc={() => this.props.submit('UserSurveysForm')}
                                    />
                                </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>

                <div className='medical_card_item_header'>
                    <div className='md_item_title_image'>
                        <div style={backgroundImageStyle}></div>
                        <h1>{t('My examinations')}</h1>
                    </div>
                    {this.props.patientInfoBlock}
                    <div className='button_blck' style={fromDocItems ? { display: 'none' } : {}}>
                        <div className='show-more-btn'
                            onClick={() => {
                                this.setState({ updateItem: false })
                                this.props.reset('UserSurveysForm')
                                this.handleShow();
                            }}
                        >
                            <p className='show-more-btn-title'>{t('Add')}</p>
                        </div>
                    </div>
                </div>

                <div className='medical_card_item_desc' style={
                    this.props.auth?.userToken?.role === 3 ? {} : { display: 'none' }}>
                    <p>{t('In the section "My examinations" you can add all examinations that have been assigned to you and record or attach the results of these examinations.')}</p>
                </div>

                <div className='medical_card_item_data_block'>
                    <p className='medical_card_item_title'>{this.props.t('All data')}</p>

                    <div className='button_blck' style={fromDocItems ? { display: 'none' } : {}}>
                        {!this.state.removeMode && !!itemsList?.length && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                        {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary cancel-btn'>{t('Cancel')}</button>}
                        {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                    </div>
                    <div className='table_data'>
                        <table className={this.props.auth?.userToken?.role === 3 ? 'pointer' : ''}>
                            <tr className='centerItem'>
                                <th>{this.props.t('Name')}</th>
                                <th>{this.props.t('Clinic')}</th>
                                <th>{this.props.t('Doctor')}</th>
                                <th>{this.props.t('Specialization')}</th>
                                <th>{this.props.t('Procedures')}</th>
                                <th>{this.props.t('Date')}</th>
                                <th>{this.props.t('File')}</th>
                                <th>{this.props.t('Conclusion and recommendations')}</th>
                            </tr>
                            {itemsList instanceof Array && itemsList.map((item: any, index: any) => {
                                const filesURL = 'https://topdoktor.org/uploads/patients/examinations/';

                                return (
                                    <tr onClick={async () => {
                                        if (this.state.removeMode || forDocItem) {
                                            return;
                                        }
                                        await this.props.getPatientSurvey(item.id);
                                        this.setState({ updateItem: true })
                                        this.handleShow();
                                    }}>
                                        {!forDocItem ?
                                            <React.Fragment>
                                                <td>
                                                    {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                                    </div>}

                                                    {item.name}
                                                </td>
                                                <td>{item.clinic_id}</td>
                                                <td>{item.doctor_id}</td>
                                                <td>{item.specialization}</td>
                                                <td>{item.procedures}</td>
                                                <td>{item.date}</td>
                                                <td>{item.uploadedfiles ? item.uploadedfiles.length : 0}</td>
                                                <td>{item.conclusion}</td>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <td>
                                                    {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                                    </div>}

                                                    {item.Name}
                                                </td>
                                                <td>{item.Clinic}</td>
                                                <td>{item.Doctor}</td>
                                                <td>{item['Doctor Specialization']}</td>
                                                <td>{item.Procedures}</td>
                                                <td>{item.Date}</td>
                                                <td>
                                                    {
                                                        item.Files && item.Files.length ? item.Files.map((f: any, i: any) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <a href={filesURL + f.filename} target="_blank">
                                                                        {i + 1}
                                                                    </a>
                                                                    {item.Files.length - 1 === i ? '' : <span>,</span>}
                                                                </React.Fragment>
                                                            )
                                                        }) : <span>Нет</span>
                                                    }
                                                </td>
                                                <td>{item.Conclusion}</td>
                                            </React.Fragment>
                                        }
                                    </tr>
                                )
                            })}
                        </table>
                        {this.props.loading.subjects.has(LoadingSubject.PatientSurveyList) ? <div style={{ marginTop: '20px' }}><LoadingCenter /></div> :
                            !itemsList || itemsList.length === 0 && <div className='no-items-no-results'> <p>{t('No data available')}</p></div>}
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    patientSurvey: state.patientSurvey,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientSurveyList,
    deletePatientSurvey,
    change,

    createPatientSurvey,
    updatePatientSurvey,
    getPatientSurvey,
    _patientSurveyItem,
};

let MySurveysComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MySurveysComponent)
);

export default MySurveysComponentContainer