import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './About.scss';
import Button from 'react-bootstrap/Button'
import { WithTranslation, withTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import history from '../../history';

import InfoBlock1 from "./../../images/about/info-block-1-1.png";
import InfoBlock2 from "./../../images/about/info-block-1-2.png";
import InfoBlock3 from "./../../images/about/info-block-1-3.png";
import Device from "./../../images/about/device.png";
import InfoBlock2_1 from "./../../images/about/info-block-2-1.png";
import InfoBlock2_2 from "./../../images/about/info-block-2-2.png";
import InfoBlock2_3 from "./../../images/about/info-block-2-3.png";
import InfoBlock2_4 from "./../../images/about/info-block-2-4.png";
import AboutSlider1 from "./../../images/about/about-slider-1.png";
import AboutSlider2 from "./../../images/about/about-slider-2.png";
import AboutSlider3 from "./../../images/about/about-slider-3.png";



interface MyProps { }

interface MyState {
    slider_index: number;
    slider_desc: Array<string>;
}

class AboutComponent extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);
        this.state = {
            slider_index: 1,
            slider_desc: [
                'Step 1 - sign up',
                'Step 2 - select doctor',
                'Step 3 - consultation'
            ]
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className='about-header-block'>
                    <p className='about-header-title'>Bizim həkimlərimiz 7/24 sizin xidmətinizdədir!</p>
                    <p className='about-header-desc'>Tibbi yardıma yanaşmanızın əsasında həkim kabinetinin
                        divarlarını aradan qaldıraraq, harada olmanızdan asılı olmayaraq, Sağlamlığınıza dəstək
                        vermək durur.</p>
                </div>
                <p className='about-header-blue-line'>"Experienced, licensed doctors working in Azerbaijan
                    and abroad provide consultations via Video Doctor."</p>

                <div className='info-block-1'>
                    <div className='info-block-1-1'>
                        <h1 className='info-block-1-1-title'>Tibbi yardımda müasir və səmərəli  yanaşma</h1>
                        <p className='info-block-1-1-desc' >
                            Dünyanın əksər ölkəsində koronavirusa yoluxma əldə edilməsi üçün video məsləhətlər artıq normaya
                            çevrilib.</p>
                        <img src={InfoBlock1} style={{ float: 'right', width: '190px', marginLeft: '20px' }} />
                        <p className='info-block-1-1-desc' >
                            Video Doktor pasiyentlər üçün həkim və ya psixoloq qəbuluna düşməyin ən rahat,
                            səmərəli və əlverişli vasitədir.</p>
                        <Button className='info-button' onClick={() => history.push('/doctors')} style={{ marginTop: '18px' }} variant="primary">{t('See a doctor')}</Button>
                    </div>
                    <div className='info-block-1-2'>
                        <h1 className='info-block-1-2-title'>Uyğun və şəffaf qiymətlər</h1>
                        <p className='info-block-1-2-desc'>
                            Video Doktor-un xidmətlərindən Azərbaycanda və onun hududlarından kənarda yaşayan istənilən
                            şəxs faydalana bilər.</p>
                        <img src={InfoBlock2} style={{ float: 'left', width: '190px', marginRight: '20px' }} />
                        <p className='info-block-1-2-desc'>
                            Qeydiyyatdan keçmək ödənişsizdir və hər bir  həkim qəbulunun xərci
                            hər zaman öncədən – daha sonra sürpriz hesablar təqdim edilmədən Sizə bildirilir.</p>
                        <Button className='info-button' onClick={() => history.push('/doctors')} style={{ marginTop: '0' }} variant="primary">{t('See a doctor')}</Button>
                    </div>
                    <div className='line-break'></div>
                    <div className='info-block-1-3'>
                        <h1 className='info-block-1-3-title'>Sizi anlayan mərhəmətli və güvənli  tibb mütəxəssisləri </h1>
                        <img src={InfoBlock3} style={{ float: 'left', width: '300px', marginRight: '20px' }} />
                        <p className='info-block-1-3-desc' style={{ width: '95%' }}>
                            Video Doktor-un xidmətlərindən Azərbaycanda və onun hududlarından kənarda yaşayan istənilən
                            şəxs faydalana bilər. Sığortalı və ya sığortasız pasiyentlər Video Doktor-dan rahat stifadə
                            edə bilər. Qeydiyyatdan keçmək ödənişsizdir və hər bir  həkim qəbulunun xərci hər zaman
                            öncədən – daha sonra sürpriz hesablar təqdim edilmədən Sizə bildirilir. </p>
                        <Button className='info-button' onClick={() => history.push('/doctors')} variant="primary">{t('See a doctor')}</Button>
                    </div>
                </div>

                <div className='about-mob-app'>
                    <h1 style={{ fontSize: '25px', textAlign: 'center' }}>Why it is worth choosing VideoDoctor.</h1>
                    <p style={{ fontSize: '15px', textAlign: 'center' }}>
                        Consultation on a wide range of questions related to prevention and treatment of various diseases</p>
                    <div className='app-mob-wrapper'>
                        <div className='app-mob-1'>
                            <p style={{ marginTop: '110px' }} className='app-mob-p-1'>A wide range of doctors is available!</p>
                            <p className='app-mob-p-1'>At a time that is convenient for you, in a form that is comfortable for you!</p>
                            <p className='app-mob-p-1'>Without waiting in line at the doctor!</p>
                            <p className='app-mob-p-1'>Save time and money!</p>
                        </div>
                        <div className='app-mob-2'>
                            <img src={Device}
                                alt='app_img1.png' style={{ width: '280px' }} />

                        </div>
                        <div className='app-mob-3'>
                            <p style={{ marginTop: '110px' }} className='app-mob-p-3'>No need to worry about parking!</p>
                            <p className='app-mob-p-3'>Comfortable when traveling!</p>
                            <p className='app-mob-p-3'>By maintaining privacy!</p>
                            <p className='app-mob-p-3'>Viruses in the clinic - no!</p>
                        </div>
                    </div>
                </div>

                <div className='info-block-2'>
                    <div className='info-block-2-1'>
                        <h1 className='info-block-2-1-title'>Təcili hallarda tibbi qayğı və dəstək</h1>
                        <img style={{ float: 'right', width: '35%', marginLeft: '20px' }}
                            src={InfoBlock2_1} alt='info-block-2-1-img' />
                        <p className='info-block-2-1-desc' >
                            Xəstə olduğunuz və həkimlə görüşə ehtiyac duyduğunuz zaman, Video Doktor-un peşəkar
                            komandası hər an Sizin yanınızdadır. Müxtəlif tibb müəssisələrində 24/7 iş başında  olan
                            mütəxəssislərimiz, Sizi düzgün istiqamətləndirməklə köməklik göstərə, canlı müayinə üçün
                            qəbul edə, diaqnostika apara və müalicə də başlada bilər. Siz həmçinin 24/7  müddətində
                            müvafiq diaqnostik müayinələr üçün təyinat ala, dərman terapiyası üçün resept əldə edə bilərsiniz.
                            Video Doktor mütəxəssislərinə etibar etməklə, Siz sağlam- lığınızın yaxşılaşdırılması yolunda
                            narahatlıqlar və kor-koranə davranışdan xilas olacaqsınız. . </p>
                    </div>
                    <div className='line-break'></div>
                    <div className='info-block-2-2'>
                        <h1 className='info-block-2-2-title'>Təcili hallarda tibbi qayğı və dəstək</h1>
                        <p className='info-block-2-2-desc'>
                            Video Doktor mobil tətbiqi evinizdən çıxmadan, emosional və psikoloji dəstək
                            əldə etməyin ən əlverişli yoludur. Pandemiya dövründə dünyanın bir çox ölkəsində
                            psixiatr və psixoloqlar hazırda hər gün minlərlə insana tələb olunan yardım göstərir </p>
                        <img style={{ width: '100%', margin: '107px auto', display: 'block' }}
                            src={InfoBlock2_2} alt='info-block-2-2-img' />

                    </div>
                    <div className='info-block-2-3'>
                        <h1 className='info-block-2-3-title'>Profilaktik təbabət</h1>
                        <p className='info-block-2-3-desc' style={{ width: '95%' }}>
                            “Ən gözəl müdafiə - profilaktikadır” ifadəsi müasir tibb elminin və xüsusilə də, son illərdə
                            populyarlaşan “individuallaşdırılmış təbabət”in əsasını təşkil edir. Sağlam həyat tərzinin
                            dəstəklənməsi, xəstəliklərin inkişafına səbəb olan, mövcud xəstəliklərin ağırlaşması və ya
                            proqressivləşməsinə təsir edən risk faktorları və davranışların müəyyənləşdirilməsi və onların
                            aradan qaldırılması Video Doktor-un məxsusi diqqət yetirdiyi və təşvqi etkiyi məsələlərdir.
                            Sizin sağlamlığınız - bizim mükafatımızdır!</p>
                        <img style={{ width: '100%', margin: '0 auto', marginBottom: '0', display: 'block' }}
                            src={InfoBlock2_3} alt='info-block-2-3-img' />
                    </div>
                    <div className='info-block-2-4'>
                        <img style={{ float: 'left', width: '50%', marginRight: '20px' }}
                            src={InfoBlock2_4} alt='info-block-2-4-img' />
                        <h1 className='info-block-2-4-title'>Təcili hallarda tibbi qayğı və dəstək</h1>
                        <p className='info-block-2-4-desc' >
                            Xəstə olduğunuz və həkimlə görüşə ehtiyac duyduğunuz zaman, Video Doktor-un peşəkar
                            komandası hər an Sizin yanınızdadır. Müxtəlif tibb müəssisələrində 24/7 iş başında  olan
                            mütəxəssislərimiz, Sizi düzgün istiqamətləndirməklə köməklik göstərə, canlı müayinə üçün
                            qəbul edə, diaqnostika apara və müalicə də başlada bilər. Siz həmçinin 24/7  müddətində
                            müvafiq diaqnostik müayinələr üçün təyinat ala, dərman terapiyası üçün resept əldə edə bilərsiniz.
                            Video Doktor mütəxəssislərinə etibar etməklə, Siz sağlam- lığınızın yaxşılaşdırılması yolunda
                            narahatlıqlar və kor-koranə davranışdan xilas olacaqsınız. . </p>
                    </div>
                </div>

                <div className='titles-desc-about'>
                    <p style={{ width: '60%', margin: '0 auto', fontSize: '13px' }}>“Video Doctor” vasitəsilə müvafiq səhiyyə
                        xidmətləri “Video Doctor mütəxəssisləri” kimi tanınan müstəqil mütəxəssislər tərəfindən həyata keçirilir.
                        Həmin tibb mütəxəssisləri icazə verilmiş fəaliyyətləri çərçivəsində öz ixtisasları və lisenziyalarına əsasən
                        Sizə xidmət göstərir.</p>
                    <p style={{ width: '60%', margin: '20px auto', fontSize: '13px' }}> “Video Doctor” mobil tətbiqinin yaradıcısı
                        olan “Ezgil Medical Technologies” hər hansı tibbi yardım, psixi sağlamlığa dəstək və ya digər səhiyyə
                        xidmətləri göstərmir.</p>
                </div>

                <div className='about-slider-about'>
                    <h1 className='about-slider-title'>{t('How to get a consultation?')}</h1>
                    <Carousel fade>
                        <Carousel.Item>
                            <img style={{ height: '300px', objectFit: 'contain', margin: '16px auto', display: 'block' }}
                                src={AboutSlider1} alt='about-slider-1' />
                            <p className='about-slider-desc'>{t(this.state.slider_desc[0])}</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img style={{ height: '300px', objectFit: 'contain', margin: '16px auto', display: 'block' }}
                                src={AboutSlider2} alt='about-slider-1' />
                            <p className='about-slider-desc'>{t(this.state.slider_desc[1])}</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img style={{ height: '300px', objectFit: 'contain', margin: '16px auto', display: 'block' }}
                                src={AboutSlider3} alt='about-slider-1' />
                            <p className='about-slider-desc'>{t(this.state.slider_desc[2])}</p>
                        </Carousel.Item>
                    </Carousel>
                </div>

            </div >
        )
    }
}

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = {

};

let AboutComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(AboutComponent)
);

export default AboutComponentContainer