import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { IDictionary } from "../../models/dictionary";
import { Link } from "react-router-dom";
import { i18n, TFunction } from "i18next";
import "./alphabet.scss";

interface AlphabetProps {
  specializationTypes?: IDictionary[];
  specializationSorted?: { [key: number]: IDictionary };
  clinicsSorted?: { [key: number]: IDictionary };
}

interface AlphabetState {
  showClinics: boolean;
  showSpecializations: boolean;
  showDisease: boolean;
}

class AlphabetComponent extends React.Component<
  AlphabetProps & WithTranslation,
  AlphabetState
> {
  constructor(props: AlphabetProps & WithTranslation) {
    super(props);
    this.state = {
      showClinics: false,
      showSpecializations: false,
      showDisease: false,
    };
  }

  showClinicsHandler() {
    this.setState({ showClinics: !this.state.showClinics });
  }

  showSpecializationsHandler() {
    this.setState({ showSpecializations: !this.state.showSpecializations });
  }

  render() {
    const { t, i18n: { language } } = this.props;

    return (
      <>
        <div className="content-block">
          <h1 className="big-title">{t("Select a clinic")}</h1>
          <div
            className={this.state.showClinics ? "alphabet opened" : "alphabet"}
          >
            {this.props.clinicsSorted &&
              Object.keys(this.props.clinicsSorted).map((letter, i) => (
                <div className="alphabet-item" key={letter}>
                  <p className="alphabet-item-title">{letter}</p>
                  <div className="alphabet-data-container">
                    {this.props.clinicsSorted &&
                      this.props.clinicsSorted[letter].map((clinic) => (
                        <Link
                          to={`/clinics?name=${clinic.name}`}
                          key={clinic.id}
                        >
                          <p key={clinic.name} className="alphabet-data-name">
                            {clinic.name}
                          </p>
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
            {!this.state.showClinics && <div className="gradient" />}
          </div>
          <div className="btn-home-alphabet-wrapper">
            <button
              onClick={this.showClinicsHandler.bind(this)}
              className="blue-btn btn btn-primary"
            >
              {this.state.showClinics ? t("Minimize") : t("Show more")}
            </button>
          </div>
        </div>
        <div className="content-block">
          <h1 className="big-title">{t("Select doctor by specialization")}</h1>
          <div
            className={
              this.state.showSpecializations ? "alphabet opened" : "alphabet"
            }
          >
            {this.props?.specializationSorted &&
              Object.keys(this.props?.specializationSorted).sort((a, b) => a?.localeCompare(b, language)).map((letter, i) => (
                <div className="alphabet-item" key={letter}>
                  <p className="alphabet-item-title">{letter}</p>
                  <div className="alphabet-data-container">
                    {this.props?.specializationSorted &&
                      this.props?.specializationSorted[letter].sort((a, b) => a.toString().localeCompare(b.toString(), language)).map((spec) =>
                      (
                        <Link
                          to={`/doctors?specialization=${spec.id}`}
                          key={spec.id}
                        >
                          <p key={spec.name} className="alphabet-data-name">
                            {spec.name}
                          </p>
                        </Link>
                      ))}

                  </div>
                </div>
              ))}
            {!this.state.showSpecializations && <div className="gradient" />}
          </div>
          <div className="btn-home-alphabet-wrapper">
            <button
              onClick={this.showSpecializationsHandler.bind(this)}
              className="blue-btn btn btn-primary"
            >
              {this.state.showSpecializations ? t("Minimize") : t("Show more")}
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clinicsSorted: state.dictionary.clinicsSorted,
  specializationTypes: state.dictionary.specializationTypes,
  specializationSorted: state.dictionary.specializationSorted,
});

const mapDispatchToProps = {};

const Alphabet = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlphabetComponent);

export default withTranslation()(Alphabet);
